import React from "react"

import Layout from "../components/layout-scene"
import SEO from "../components/seo"

import { rhythm } from '../utils/typography'
import styled from "@emotion/styled";
import Scene from "../components/scenes/scene2";

import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import toscana from "../images/draw-toscana.jpg";
import cool from "../images/draw-sh.jpg"

const slider = (
    <AwesomeSlider>
        <div>1</div>
        <div>2</div>
        <div>3</div>
        <div>4</div>
    </AwesomeSlider>
);

const Header = styled('div')`
  display: flex;
  flex-direction: row;
  justify-items: center;
  align-items: center;
  flex-wrap: wrap;
  @media (max-width: ${rhythm(25)})  {
    flex-direction: column;
    & > * + * {
      margin-top: ${rhythm(0.5)}; 
    }
    div {
      align-items: center !important;
    }
  }
  
`



const SecondPage = () => (
  <Layout>
    <SEO title="Projects" />
      <div style={{position: `relative`}}>
            {/*<Scene />*/}
          <AwesomeSlider animation="cubeAnimation">
              <div>
                  <img style={{width: `400px`, height: `auto`, margin: `20px`}} src={cool} alt={''}/>
              </div>

              <div>
                <img style={{width: `400px`, height: `auto`}} src={toscana} alt={''}/>
              </div>
          </AwesomeSlider>
      </div>
  </Layout>
)

export default SecondPage
